@import "./styles.common.module.scss";

.wrap {
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  position: relative;
}

.missing {
  height: 100%;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  overflow: hidden;
  background-color: #bababa;
  svg {
    color: #fff;
  }
}

.play {
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  z-index: 6;
}

.ribbon {
  z-index: 4;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 250ms;
  z-index: 3;
  border-radius: calc(var(--card-tile-radius) * 1rem);

  &.playing {
    opacity: 1;
  }
}

// opacity on image while loading video
.fadeIn,
.fadeOut {
  transition: all 500ms;
}
.fadeIn {
  opacity: 0;
}
.fadeOut {
  opacity: 1;
}

.container {
  cursor: pointer;
  position: relative;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  user-select: none;
  flex-shrink: 0;
  transition: transform 0.2s; /* Animation */
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}

:global {
  .lowend {
    :local {
      .container {
        transition: unset;
      }
    }
  }
}
