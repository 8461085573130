@import "../../dimens.scss";

.container {
  flex: 1;
  flex-direction: column;
  padding: #{$margins-lightgreen + rem};
  display: flex;
  @media (min-width: #{$breakpoints-desktop}px) {
    padding: #{$margins-yellow + rem} #{$margins-bluegreen + rem} 0 #{$margins-bluegreen +
      rem};
    max-width: 120rem;
    min-width: 88rem;
    margin: auto;
  }
}

.items {
  display: flex;
  flex-direction: column;
  & > * {
    margin: #{$margins-darkred + rem} 0;
  }
  padding: 0;
  margin: 0;
  list-style: none;
}

.card {
  background-color: var(--card-inactive-background);
  border-radius: calc(var(--card-radius) * 1rem);
  padding: #{$margins-green + rem};
  display: flex;
  align-items: center;
  @include defaultText();
  color: var(--card-inactive-textColor);
  transition: transform 0.5s;
  cursor: pointer;
  svg {
    margin-right: #{$margins-green + rem};
    &:last-of-type {
      display: none;
    }
  }
  &:focus,
  &:hover {
    transform: scale(var(--card-account-scaleFactor));
    background-color: var(--card-active-background);
    color: var(--card-active-textColor);
    svg:first-of-type {
      display: none;
    }
    svg:last-of-type {
      display: block;
    }
  }
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    width: 4rem;
  }
}
