.arrowBackWhite {
  svg {
    color: white !important;
  }
}

.arrowBackRed {
  svg {
    color: var(--accent-color) !important;
  }
}

.errorWebRTC {
  color: var(--error-color);
  font-size: 1.2rem;
}
