@import "../../dimens.scss";

@mixin desktop-active-state {
  background-color: var(--bottombar-tint-color-active);
  box-shadow: 0 0 1.2rem var(--bottombar-tint-color-active);
  &::after {
    background-color: var(--bottombar-tint-color-active);
    display: block;
    border-radius: 0 3.2rem 3.2rem 0;
    box-shadow: 0 0 1.2rem var(--bottombar-tint-color-active);
  }
  svg {
    color: var(--bottombar-tint-color-inactive-focused);
  }
}

.focusable {
  flex: 1 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: min-content;

  @media screen and (min-width: #{$breakpoints-desktop}px) and (orientation: landscape) {
    flex: 0;
    margin-bottom: #{$margins-green + rem};
  }

  &:focus {
    .link.spatialActive {
      svg {
        color: var(--accent-color);
      }
    }
  }
  &:focus {
    .link.active.spatialActive {
      svg {
        color: var(--accent-color);
      }
    }
  }

  @media (min-width: #{$breakpoints-tablet}px) and (orientation: landscape) {
    &:focus {
      .link.active.spatialActive,
      .link.spatialActive {
        @include desktop-active-state();
      }
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  transition: all 0.2s;

  &.active {
    svg {
      color: var(--bottombar-tint-color-active);
    }
  }

  @media (orientation: landscape) {
    position: relative;
    padding: #{$margins-green + rem};
  }

  @media (min-width: #{$breakpoints-tablet}px) and (orientation: landscape) {
    position: relative;
    padding: #{$margins-green + rem};

    &:after {
      position: absolute;
      right: -1rem;
      top: 0;
      bottom: 0;
      width: 1rem;
      content: "";
      z-index: 101;
      display: none;
    }
  }

  @media (min-width: #{$breakpoints-tablet}px) and (hover: hover) {
    &:hover {
      background-color: var(--bottombar-tint-color-active);
      box-shadow: 0 0 1.2rem var(--bottombar-tint-color-active);

      &::after {
        background-color: var(--bottombar-tint-color-active);
        display: block;
        border-radius: 0 3.2rem 3.2rem 0;
        box-shadow: 0 0 1.2rem var(--bottombar-tint-color-active);
      }
      svg {
        color: var(--bottombar-tint-color-active-focused);
      }
    }
    &.active:hover {
      svg {
        color: var(--bottombar-tint-color-active-focused);
      }
    }
  }
}

:global {
  .lowend {
    :local {
      .link {
        transition: unset;
      }
    }
  }
}
