@import "../../dimens.scss";

.container {
  flex: 1;
  flex-direction: column;
  padding: #{$margins-lightgreen + rem} 0;
  display: flex;

  @media (min-width: #{$breakpoints-desktop}px) {
    padding: #{$margins-yellow + rem} 0 #{$margins-lightgreen + rem} 0;
    max-width: 63rem;
    margin: auto;
  }
}
.subtitle {
  @include defaultText();
  margin-bottom: #{$margins-lightgreen + rem};
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 #{$margins-lightgreen + rem};
}
.inputCard {
  background-color: var(--card-inactive-background);
  border-radius: calc(var(--card-radius) * 1rem);
  padding: #{$margins-green + rem};
  display: flex;
  align-items: center;
  color: var(--text2-color);
  font-size: calc(var(--text2-size) * 1rem);
  font-weight: var(--text2-weight);
  font-family: var(--text2-font);
  text-align: start;
  margin-bottom: #{$margins-lightgreen + rem};
}
.input {
  margin: 0 #{$margins-darkred + rem};
  flex: 1;
}

.id {
  width: 3rem;
  height: 3rem;
}
.eye {
  color: var(--text-color);
  cursor: pointer;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover,
  &:focus {
    color: var(--accent-color);
  }
}

.copy {
  background-color: var(--card-inactive-background) !important;
  color: var(--text-color) !important;
  &:hover,
  &:focus {
    background-color: var(--card-inactive-background) !important;
    color: var(--accent-color) !important;
  }
}

.footer {
  margin-top: #{$margins-lightgreen + rem};
  padding-top: #{$margins-green + rem};
  border-top: 0.1rem solid var(--separator-color);
  display: flex;
  justify-content: center;
  @media (min-width: #{$breakpoints-desktop}px) {
    display: none;
  }
}

.support {
  font-size: 1.3rem;
  svg {
    width: 1rem;
    height: auto;
    margin-left: #{$margins-darkred + rem};
  }
}
